import { parsePhoneNumber, getExample } from 'awesome-phonenumber';
import { countries } from './countries';

const exceptions = [{ code: '+27', areaCode: '075', length: 10 }];
const blacklist = ['0660000000'];

export const validateCell = (number: string, code: string) => {
  const country = countries.filter((c) => c.code === code);
  const regionCode = country[0].flag.slice(-2).toUpperCase();
  const pn = parsePhoneNumber(number, regionCode);
  const exampleNumberLength = getExample(regionCode, 'mobile')
    .getNumber('national')
    .replace(/\s/g, '').length;

  try {
    if (blacklist.includes(pn.toJSON().number.national.replaceAll(' ', ''))) {
      return false;
    }
  } catch (e) {
    console.log(e);
  }
  return (
    (pn.isValid() && number.length >= exampleNumberLength) ||
    !!exceptions.find((exception) => {
      return (
        code === exception.code &&
        number.startsWith(exception.areaCode) &&
        number.length === exception.length
      );
    })
  );
};

export const getFormattedCell = (number: string, code: string) => {
  const country = countries.filter((c) => c.code === code);
  const regionCode = country[0].flag.slice(-2).toUpperCase();
  const pn = parsePhoneNumber(number, regionCode);

  return pn.getNumber();
};

export const isPossibleCell = (number: string, code: string) => {
  const country = countries.filter((c) => c.code === code);
  const regionCode = country[0].flag.slice(-2).toUpperCase();
  const pn = parsePhoneNumber(number, regionCode);
  const exampleNumberLength = getExample(regionCode, 'mobile')
    .getNumber('national')
    .replace(/\s/g, '').length;

  return (
    (pn.isPossible() && number.length >= exampleNumberLength) ||
    !!exceptions.find((exception) => {
      return (
        code === exception.code &&
        number.startsWith(exception.areaCode) &&
        number.length === exception.length
      );
    })
  );
};
